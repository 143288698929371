<script lang="ts" setup>
import { ref, computed } from "vue";
import { iamType } from "@/services/api/iam.api";
import AccessGrid from "@/components/iam/AccessGrid.vue";
import ResourcesGrid from "@/components/iam/ResourcesGrid.vue";
import RelationshipSelector from "@/components/iam/RelationshipSelector.vue";

const emits = defineEmits(["add-relationship", "remove-relationship"]);

const props = defineProps({
  viaAccess: undefined,
  refreshKey: undefined,
});

const currentType = iamType.Resource;

const selectorVisible = ref(false);

const allowAdd = computed(() => props.viaAccess.domain == iamType.User || props.viaAccess.domain == iamType.UserGroup);
const accessViaPermissionCategory = computed(() => props.viaAccess.domain == iamType.PermissionCategory);

const preFilter = computed(() => {
  if (accessViaPermissionCategory.value) {
    return [
      {
        fieldName: "permissionCategory:id",
        exact: [props.viaAccess.id],
      },
    ];
  }
  return undefined;
});

function onAddRelationship(selection) {
  emits("add-relationship", selection);
}

async function onRemoveRelationship(id) {
  emits("remove-relationship", id);
}
</script>

<template>
  <div>
    <resources-grid v-if="accessViaPermissionCategory" :pre-filter="preFilter" allow-remove="true" @remove-relationship="onRemoveRelationship" :refreshKey="props.refreshKey">
      <template #add-relation>
        <v-btn dark class="primary mt-4" @click.stop="selectorVisible = true">
          <v-icon dark center>mdi-playlist-plus</v-icon>
        </v-btn>
      </template>
    </resources-grid>
    <access-grid v-else :preFilter="preFilter" allow-remove="true" @remove-relationship="onRemoveRelationship" :viaAccess="viaAccess" :currentType="currentType" :refreshKey="props.refreshKey">
      <template #add-relation v-if="allowAdd">
        <v-btn dark class="primary mt-4" @click.stop="selectorVisible = true">
          <v-icon dark center>mdi-playlist-plus</v-icon>
        </v-btn>
      </template>
    </access-grid>
    <relationship-selector v-model="selectorVisible" @add-relationship="onAddRelationship" :domain-type="viaAccess.domain" :selection-type="currentType"> </relationship-selector>
  </div>
</template>
